<template>
  <div>
    <div>
      <AlertErrors :form="filters" />

      <b-form @submit.prevent="fetchAnalytics()" inline>
        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Appointment From Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.appointment_from_date"
            type="date"
            :state="filters.errors.has('appointment_from_date') ? false : null"
            :required="filters.appointment_to_date != ''"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Appointment To Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.appointment_to_date"
            type="date"
            :state="filters.errors.has('appointment_to_date') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Overdue Call Hour
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.overdue_call_hour"
            type="number"
            placeholder="Default: 3 hour"
            :state="filters.errors.has('overdue_call_hour') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" block>Analyse</b-button>
      </b-form>
    </div>

    <div class="mt-5">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton width="40%" height="50px"></b-skeleton>
        </template>

        <h1 class="text-dark">
          Visits Count: {{ analytics ? analytics[0].visits_count : 0 }}
        </h1>
      </b-skeleton-wrapper>
    </div>

    <!-- Statuses & Overdues -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Elderly Calls for Visits</h6>
          <small>
            Showing the visits with different call statuses and their
            percentages.
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="callsChartOptions"
            :series="callsChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>

      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Overdue Calls</h6>
          <small>
            Showing the overdue calls within
            {{ filters.overdue_call_hour ? filters.overdue_call_hour : 1 }}
            hour{{
              (filters.overdue_call_hour ? filters.overdue_call_hour : 1) > 1
                ? "s"
                : ""
            }}
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="radialBar"
            :options="overdueCallChartOptions"
            :series="overdueCallChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "VisitsAnalytics",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: true,
      filters: new this.$Form({
        appointment_from_date: "",
        appointment_to_date: "",
        overdue_call_hour: "",
      }),
      analytics: null,
      simplePieChartOptions: {
        chart: {
          width: "100%",
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      simpleRadialBarChartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    overdueCallChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simpleRadialBarChartOptions));

      chart.chart.offsetY = -20;

      (chart.plotOptions = {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      }),
        (chart.grid = {
          padding: {
            top: -10,
          },
        });

      // chart.fill = {
      //   type: 'gradient',
      //   gradient: {
      //     shade: 'light',
      //     shadeIntensity: 0.4,
      //     inverseColors: false,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [0, 50, 53, 91]
      //   },
      // };

      chart.colors = ["#FF9800"];

      return chart;
    },
    overdueCallChartSeries() {
      if (!this.analytics) {
        return [0];
      }

      return [
        (this.analytics[0].visit_with_overdue_calls * 100) /
          this.analytics[0].visits_count,
      ];
    },
    callsChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Responded", "Unreachable", "Retry", "Declined"];

      chart.colors = ["#0084ff", "#FF9800", "#1ab7ea", "#ff000d"];

      return chart;
    },
    callsChartSeries() {
      if (!this.analytics) {
        return [0, 0, 0, 0];
      }

      return [
        this.analytics[0].visits_with_responded_calls,
        this.analytics[0].visits_with_unreachable_calls,
        this.analytics[0].visits_with_retry_calls,
        this.analytics[0].visits_with_declined_calls,
      ];
    },
  },
  methods: {
    fetchAnalytics() {
      this.loading = true;

      this.filters
        .get(endpoints.GET_VISITS_ANALYTICS)
        .then((response) => {
          this.analytics = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAnalytics();
  },
};
</script>
